import { FixedAmountPromotion } from '@commercelayer/sdk/lib/resources/fixed_amount_promotions';
import { FixedPricePromotion } from '@commercelayer/sdk/lib/resources/fixed_price_promotions';
import { Coupon } from '@commercelayer/sdk/lib/resources/coupons';
import { Promotion } from '@commercelayer/sdk/lib/resources/promotions';

import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';
import EcomClient from 'shared/infra/ecom/client';

export type ISupportedPromotion = FixedAmountPromotion | FixedPricePromotion;

export const IPromotionType = {
  fixed_amount_promotions: 'fixed_amount_promotions' as Promotion['type'],
  fixed_price_promotions: 'fixed_price_promotions' as Promotion['type'],
} as const;

export interface IPromotions {
  name: ISupportedPromotion['name'];
  couponCode: Coupon['code'];
  fixedAmountCents: ISupportedPromotion['fixed_amount_cents'];
  fixedAmountFloat: ISupportedPromotion['fixed_amount_float'];
  formattedFixedAmount: ISupportedPromotion['formatted_fixed_amount'];
  type: Promotion['type'];
  purchaseLimit?: number;
}

const fetchPromotionByCouponCode = async (couponCode: string) => {
  const ecom = await EcomClient.getClient();
  const promotion = await ecom.promotions.list({
    include: ['sku_list_promotion_rule', 'sku_list_promotion_rule.skus'],
    filters: {
      coupon_codes_promotion_rule_coupons_code_eq:
        processCouponCodeToOrder(couponCode),
    },
  });

  return promotion;
};

export const parsePromotionData = (
  couponCode: string,
  promotion: ISupportedPromotion,
): IPromotions => ({
  name: promotion.name,
  couponCode,
  fixedAmountCents: promotion.fixed_amount_cents,
  fixedAmountFloat: promotion.fixed_amount_float,
  formattedFixedAmount: promotion.formatted_fixed_amount,
  type: promotion.type,
  purchaseLimit: promotion.metadata.purchase_limit as number,
});

export const isPromotionValid = (promotion: ISupportedPromotion): boolean => {
  if (!promotion) {
    return false;
  }

  const isPromotionActive = Boolean(
    promotion && promotion.active && promotion.sku_list_promotion_rule,
  );
  const isPromotionSupported =
    promotion?.type === IPromotionType.fixed_amount_promotions ||
    promotion?.type === IPromotionType.fixed_price_promotions;

  return isPromotionActive && isPromotionSupported;
};

export const getSkuPromotionByCouponCode = async (
  couponCode: string,
): Promise<Record<string, IPromotions>> => {
  const [promotion]: ISupportedPromotion[] = await fetchPromotionByCouponCode(
    couponCode,
  );

  if (!isPromotionValid(promotion)) {
    return {};
  }

  const promotionsBySkuMap = promotion.sku_list_promotion_rule.skus.reduce(
    (acc, sku) => ({
      ...acc,
      [sku.code]: parsePromotionData(couponCode, promotion),
    }),
    {},
  );

  return promotionsBySkuMap;
};

export const getPromotionsByCouponCode = async (
  coupons: string[],
): Promise<Record<string, IPromotions>> => {
  // We have to fetch each promo code separately because we otherwise wouldn't
  // know which coupon belongs to which promotoin.
  // CommerceLayer doesn't allow to include coupons in the response (for fraud reasons)
  const promotionsBySku = await Promise.all(
    coupons.map((coupon) => getSkuPromotionByCouponCode(coupon)),
  );

  const parsedPromotions = promotionsBySku.reduce(
    (acc, promotion) => ({
      ...acc,
      ...promotion,
    }),
    {},
  );

  return parsedPromotions;
};
