let isPreview = false;

export function previewInfo() {
  return {
    getPreviewMode: () => isPreview,
    setPreview: (preview: boolean) => {
      isPreview = preview;
    },
  };
}
