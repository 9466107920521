import { useCallback } from 'react';
import { Order } from '@commercelayer/sdk';

import useGetOrder from './useGetOrder';

import { useTypedSelector } from 'shared/store';
import { IOrderDetails } from 'shared/store/order/types';
import { CLAYER_ORDER_ID } from 'shared/infra/storage';
import isServer from 'shared/utils/is-server';

const getLocalOrderID = (localOrder: IOrderDetails): string => {
  try {
    return localStorage.getItem(CLAYER_ORDER_ID) || localOrder.id;
  } catch (err) {
    // ignore if localStorage is not available.
    if (!isServer) {
      // eslint-disable-next-line no-console
      console.error('Unable to get local order id!');
    }
    return null;
  }
};

// TODO: This hook is part of the refactoring initiative.
// This means that it only reflects the old loadOrderSaga without changes.
// To be refactored when redux saga is removed!
const useLoadLocalOrder = () => {
  const getOrder = useGetOrder();

  const localOrder = useTypedSelector((state) => state.order.orderDetails);

  return useCallback(async (): Promise<Order> => {
    const id = getLocalOrderID(localOrder);
    if (!id) {
      return;
    }

    return getOrder(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoadLocalOrder;
