import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Order } from '@commercelayer/sdk';
import debounce from 'lodash.debounce';

import * as clOrderAPI from 'shared/infra/commerceLayer/orders';
import {
  dispatchChangeProductQuantityEvent,
  dispatchChangeProductQuantityFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
} from 'shared/services/tracker';
import {
  changeProductQuantity,
  changeProductQuantityFailure,
  changeProductQuantitySuccess,
  removeProduct,
  removeProductFailure,
  removeProductSuccess,
} from 'shared/store/order/actions';
import useLoadLocalOrder from 'shared/hooks/orders/useLoadLocalOrder';
import { useTypedSelector } from 'shared/store';

export interface IProductInfo {
  id: string;
  trackingId: string;
  reference?: string;
  formattedUnitAmount?: string;
}

export const useLineItems = () => {
  const dispatch = useDispatch();
  const loadLocalOrder = useLoadLocalOrder();
  const localOrder = useTypedSelector((state) => state.order.orderDetails);

  const removeLineItem = useCallback(
    async (product: IProductInfo) => {
      try {
        // TODO: this dispatch only sets the state to
        // loading. We must revisit our loading strategy.
        dispatch(removeProduct(product.reference));

        await clOrderAPI.removeLineItems(product.id);

        dispatch(removeProductSuccess());
        dispatchRemoveProductEvent({
          product: {
            id: product.reference,
            // TODO: check if this is correct or needs catalog info?
            trackingId: product.trackingId,
          },
        });
      } catch (e) {
        console.error(e);
        dispatch(removeProductFailure('Unable to remove product from order.'));
        dispatchRemoveProductFailureEvent({
          product: {
            id: product.id,
            trackingId: product.trackingId,
          },
        });
      }
    },
    [dispatch],
  );

  const adjustLineItemQuantity = useCallback(
    async (product: IProductInfo, quantity: number) => {
      try {
        // only sets the loading state correctly.
        dispatch(
          changeProductQuantity(product.id, quantity, product.reference),
        );

        await clOrderAPI.updateLineItems(product.id, { quantity });

        dispatch(changeProductQuantitySuccess());
        dispatchChangeProductQuantityEvent({
          product: {
            id: product.reference,
            trackingId: product.trackingId,
            quantity,
            price: product.formattedUnitAmount,
          },
        });
      } catch (e) {
        console.error(e);
        dispatch(
          changeProductQuantityFailure('Unable to change product quantity'),
        );
        dispatchChangeProductQuantityFailureEvent({
          product: {
            id: product.reference,
            trackingId: product.trackingId,
            quantity,
            price: product.formattedUnitAmount,
          },
        });
      }
    },
    [dispatch],
  );

  const ensureLineItemQuantity = useCallback(
    (product: IProductInfo, quantity: number) => {
      if (quantity === 0) {
        return removeLineItem(product);
      }

      return adjustLineItemQuantity(product, quantity);
    },
    [removeLineItem, adjustLineItemQuantity],
  );

  const changeLineItemQuantity = useCallback(
    async (product: IProductInfo, quantity: number): Promise<Order> => {
      await ensureLineItemQuantity(product, quantity);
      await clOrderAPI.updateTaxes(localOrder.id);

      return loadLocalOrder();
    },
    [ensureLineItemQuantity, loadLocalOrder, localOrder.id],
  );

  //eslint-disable-next-line
  const changeLineItemQuantityDebounced = useCallback(
    debounce(changeLineItemQuantity, 500),
    // must be the same as changeLineItemQuantity
    [ensureLineItemQuantity, loadLocalOrder, localOrder.id],
  );

  return {
    removeLineItem,
    changeLineItemQuantity,
    changeLineItemQuantityDebounced: changeLineItemQuantityDebounced,
  };
};
