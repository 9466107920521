import querystring from 'querystring';
import type { UrlObject } from 'url';
import type { ParsedUrlQuery } from 'querystring';

import { OIDCClient } from '@sumup/nanoauth';
import { AuthState } from '@sumup/react-nanoauth';

import { isPreviewDeployment } from './AuthService';

import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { ShopExperience } from 'shared/constants/ShopExperience';

type Url = UrlObject | string;
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

export type FuncParams = {
  query: ParsedUrlQuery;
  locale: string;
  redirectPathname: string;
  authRedirect: typeof OIDCClient.prototype.authRedirect;
  authState: AuthState;
  push: (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean>;
  source?: ShopExperience;
  requestParams?: Record<string, string>;
};

export function redirectToSSOLogin({
  query,
  locale,
  redirectPathname,
  authRedirect,
  authState,
  push,
  source = ShopExperience.SIGNUP,
}: FuncParams): void {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { c, redirect, ...queryParams } = query;

  if (authState === AuthState.Authenticated) {
    push({ pathname: redirectPathname, query: queryParams }, undefined, {
      locale,
    });
    return;
  }

  const authRequestParams = {
    ...queryParams,
    // Localize the user interface
    ui_locales: locale,
    // Ensure users don't get a signup experience.
    source: ShopExperience.DASHBOARD,
  };
  authRedirect(
    {
      queryParams,
      redirectPathname,
      locale,
      shopExperience: source,
      ...getSourceOrigin(),
    },
    authRequestParams,
  );
}

export function redirectToSSOSignup({
  query,
  locale,
  redirectPathname,
  authRedirect,
  authState,
  push,
  source = ShopExperience.SIGNUP,
  requestParams = {},
}: FuncParams): void {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { c, redirect, ...queryParams } = query;
  if (authState === AuthState.Authenticated) {
    push({ pathname: redirectPathname, query: queryParams }, undefined, {
      locale,
    });
    return;
  }

  // pass down the query params to the child iframe so that we don't lose track of them on the way back to our app
  if (requestParams.iframe_uri) {
    requestParams.iframe_uri = `${
      requestParams.iframe_uri
    }?${querystring.stringify(queryParams)}`;
  }

  const country = getCountryCodeFromISOLocale(locale);
  const authRequestParams: Record<string, string> = {
    ...queryParams,
    ...requestParams,
    // Prepopulate the country
    country_hint: country,
    // Ensure the user cannot change their country
    force_country: true as unknown as string, // make ts happy
    // Localize the user interface
    ui_locales: locale,
  };

  if (source !== ShopExperience.DASHBOARD) {
    // Initiate a signup
    authRequestParams.prompt = 'create';
  }

  authRedirect(
    {
      queryParams,
      redirectPathname,
      locale,
      country,
      shopExperience: source,
      ...getSourceOrigin(),
    },
    authRequestParams,
  );
}

// The redirect URL for SSO needs to be white-listed in Hydra. Vercel’s
// preview deployments have unique (sub)domains but Hydra doesn’t
// support wildcard domains. We include the source origin in the app
// state so that a proxy can redirect back to the preview deployment.
function getSourceOrigin() {
  if (isPreviewDeployment()) {
    return {
      sourceOrigin: `${window.location.origin}/auth/callback`,
    };
  }

  return {};
}
