import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useGetPromotions from './useGetPromotions';

import { IAppState, useTypedSelector } from 'shared/store';
import { DEFAULT_LOCALE } from 'tools/locales';
import { getPromotionPRCs } from 'productSelection/services/promotions';

interface IPromotionsConfig {
  forSkuCode?: string;
  locale?: string;
  marketReference: string;
}

const usePromotions = (config?: IPromotionsConfig): IAppState['promotions'] => {
  const { forSkuCode, locale, marketReference } = config || {
    locale: DEFAULT_LOCALE,
  };
  const getPromotions = useGetPromotions();
  const dispatch = useDispatch();
  const promotions = useTypedSelector((state) => state.promotions);
  const skuPromotion = promotions.details[forSkuCode];

  // fetch promotions
  useEffect(() => {
    const parsedCoupons = getPromotionPRCs();
    const shouldLoadPromotions = parsedCoupons.length && !skuPromotion;
    if (shouldLoadPromotions) {
      getPromotions(parsedCoupons);
    }
    // fetching promotions depends on the locale so anytime it changes we should execute again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forSkuCode, skuPromotion, locale, marketReference]);

  return promotions;
};

export default usePromotions;
