import { captureException, withScope } from '@sentry/nextjs';
import axios from 'axios';

import { IProductDetails } from 'productSelection/types/products';

export interface IPrice {
  applied_coupon_code: string;
  price_type: string;
  promotion_purchase_limit: number | null;
  sku_code: string;
  subtotal_amount_float: number;
  subtotal_amount_with_taxes_float: number;
  total_amount_float: number;
  total_amount_with_taxes_float: number;
  total_tax_amount_float: number;
  compare_at_amount_float: number;
  compare_at_amount_with_taxes_float: number;
  stock_quantity: number | null;
}

export interface IMarketData {
  data: {
    attributes: {
      prices: IPrice[];
      tax_rate: number;
      tax_included: boolean;
    };
    id: string;
    type: string;
  };
}

export const getMarketPricesUrl = (
  marketReference: string,
  coupons?: string,
) => {
  const [, marketId] = marketReference.split(':');
  const defaultUrl = `${process.env.ECOM_PLATFORM_URL}/api/markets/${marketId}/price_list`;

  return coupons ? `${defaultUrl}?coupons=${coupons}` : defaultUrl;
};

export const getPricesByMarket = async (
  marketReference: string,
  couponsQuery?: string,
): Promise<IMarketData> => {
  try {
    const coupons = couponsQuery?.replace(/-s-/g, ',');
    const response = await axios.get(
      getMarketPricesUrl(marketReference, coupons),
    );

    return response.data;
  } catch (error) {
    withScope((scope) => {
      scope.setTags({
        marketReference,
      });
      captureException(
        new Error(`[Prices] Error getting catalog prices for market.`),
      );
    });
  }
};

export interface ISkuPriceData {
  trackingId: string;
  promotionPurchaseLimit: number;
  appliedCouponCode: string;
  skuCode: string;
  subtotalAmountFloat: number;
  sutotalAmoutWithTaxesFloat: number;
  totalAmountFloat: number;
  totalAmountWithTaxesFloat: number;
  totalTaxAmountFloat: number;
  discountAmountFloat: number;
  compareAtAmountFloat: number;
  compareAtAmountWithTaxesFloat: number;
  stockQuantity: number | null;
  priceType: string;
  hasTax: boolean;
}

export const getGalleryPricesByContentfulId = (
  products: IProductDetails[],
  marketPriceList: IMarketData,
): Record<string, ISkuPriceData> => {
  const pricesByContentfulId = products.reduce((acc, contentfulProduct) => {
    const { prices, tax_rate } = marketPriceList.data.attributes;
    const isSkuAvailableInCommerceLayer = prices.find(
      (price) => price.sku_code === contentfulProduct.sku,
    );

    if (!isSkuAvailableInCommerceLayer) {
      withScope((scope) => {
        scope.setTags({
          marketReference: marketPriceList.data.id,
          sku: contentfulProduct.sku,
        });
        captureException(
          new Error(
            `[SKU] SKU registered in Contentful is not available in Commerce Layer.`,
          ),
        );
      });

      return { ...acc };
    }

    // merge contentful data with CL
    const {
      applied_coupon_code: appliedCouponCode,
      promotion_purchase_limit: promotionPurchaseLimit,
      sku_code: skuCode,
      subtotal_amount_float: subtotalAmountFloat,
      subtotal_amount_with_taxes_float: sutotalAmoutWithTaxesFloat,
      total_amount_float: totalAmountFloat,
      total_amount_with_taxes_float: totalAmountWithTaxesFloat,
      total_tax_amount_float: totalTaxAmountFloat,
      compare_at_amount_float: compareAtAmountFloat,
      compare_at_amount_with_taxes_float: compareAtAmountWithTaxesFloat,
      stock_quantity: stockQuantity,
      price_type: priceType,
    } = prices.find(
      (skuPrice) =>
        skuPrice.sku_code === contentfulProduct.sku &&
        skuPrice.price_type === contentfulProduct.type,
    );

    const discountAmountFloat = compareAtAmountFloat - totalAmountFloat;

    return {
      ...acc,
      [contentfulProduct.id]: {
        trackingId: contentfulProduct.trackingId,
        promotionPurchaseLimit,
        appliedCouponCode,
        skuCode,
        subtotalAmountFloat,
        sutotalAmoutWithTaxesFloat,
        totalAmountFloat,
        totalAmountWithTaxesFloat,
        totalTaxAmountFloat,
        compareAtAmountFloat,
        compareAtAmountWithTaxesFloat,
        discountAmountFloat,
        // We need to add this temporarily to ensure backwards compatibility with our tracked events on GA.
        formattedAmount: totalAmountFloat.toString(),
        stockQuantity,
        priceType,
        hasTax: tax_rate > 0,
      },
    };
  }, {});

  return pricesByContentfulId;
};
