import { action } from 'typesafe-actions';

import {
  ILoadPromotionsAction,
  ILoadPromotionsFailureAction,
  ILoadPromotionsSuccessAction,
  IResetPromotionsAction,
  PromotionsTypes,
} from './types';

export const loadPromotions = (
  coupons: string[],
  scope: string,
  locale: string,
): ILoadPromotionsAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_REQUEST, {
    coupons,
    scope,
    locale,
  });

export const loadPromotionsSuccess = (
  promotionsBySku: ILoadPromotionsSuccessAction['payload']['details'],
): ILoadPromotionsSuccessAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_SUCCESS, {
    details: promotionsBySku,
  });

export const loadPromotionsFailure = (
  message?: ILoadPromotionsFailureAction['payload']['message'],
): ILoadPromotionsFailureAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_FAILURE, { message });

export const resetPromotions = (): IResetPromotionsAction =>
  action(PromotionsTypes.RESET_PROMOTIONS);
