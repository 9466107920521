import { useDispatch } from 'react-redux';

import { getPromotionsByCouponCode } from 'shared/infra/commerceLayer/promotions';
import {
  loadPromotionsFailure,
  loadPromotionsSuccess,
} from 'shared/store/promotions/actions';

interface IUseGetPromotions {
  getPromotions: (coupons: string[]) => Promise<void>;
}

const useGetPromotions = (): IUseGetPromotions['getPromotions'] => {
  const dispatch = useDispatch();
  const getPromotions = async (coupons: string[]) => {
    try {
      const promotionsBySku = await getPromotionsByCouponCode(coupons);
      dispatch(loadPromotionsSuccess(promotionsBySku));
    } catch (error) {
      console.error(error);
      dispatch(loadPromotionsFailure('Error loading promotions.'));
    }
  };

  return getPromotions;
};

export default useGetPromotions;
