import { EntryCollection } from 'contentful';
import { Order } from '@commercelayer/sdk';

import { combineProductsInfo } from './ProductService';

import {
  ICatalogFields,
  IDigitalProduct,
  IProduct,
  IBundleProduct,
  IProductBenefitFields,
  LOCALE_CODE,
} from 'shared/infra/contentful/contentful';
import {
  IDigitalProductDetails,
  IProductBenefitParsed,
  IProductDetails,
  IProduct as IProductData,
  IProductType,
} from 'productSelection/types/products';
import {
  convertImageToPng,
  forceImgSrcToHttps,
} from 'shared/services/imageUrl';
import { PRODUCT_ALIASES } from 'shared/constants/ProductConstants';
import { getCatalogEntriesByCountry } from 'shared/infra/contentful';
import { translateProductToStore } from 'shared/infra/commerceLayer/utils';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { BUSINESS_ACCOUNT_PRODUCT } from 'shared/constants/ProductConstants';

const parseContentfulProductBenefit = (
  productBenefit: IProductBenefitFields,
): IProductBenefitParsed => ({
  name: productBenefit?.name || '',
  title: productBenefit?.title || '',
  description: productBenefit?.description || '',
  icon: {
    imageSrc: forceImgSrcToHttps(productBenefit?.icon?.fields?.file?.url || ''),
    imageAltText: productBenefit?.icon?.fields?.description || '',
  },
});

export const parseContentfulDigitalProducts = (
  digitalProducts: IDigitalProduct[],
): IDigitalProductDetails[] =>
  digitalProducts.map((digitalProduct) => ({
    ...digitalProduct.fields,
    id: digitalProduct.sys.id,
    slug: digitalProduct.fields.trackingId,
    productContent: {
      imageSrc: convertImageToPng(
        digitalProduct.fields.images[0]?.fields.file.url || '',
      ),
      imageAltText: digitalProduct.fields.images[0]?.fields.description || '',
    },
    images:
      digitalProduct.fields.images?.map((image) => ({
        id: image.sys.id,
        imageSrc: convertImageToPng(image.fields.file.url),
        imageAltText: image.fields.description || '',
      })) || [],
    shouldShowHighlight: digitalProduct.fields.showProductHighlight,
    highlightTitle: digitalProduct.fields.productHighlightTitle || '',
    highlightDescription:
      digitalProduct.fields.productHighlightDescription || '',
    selectProductLabel: digitalProduct.fields.selectProductLabel || '',
    selectedProductLabel: digitalProduct.fields.selectedProductLabel || '',

    productDescription: digitalProduct.fields.productDescription || '',
    faqs: (digitalProduct.fields.faqs || []).map((item) => item.fields),

    productBenefits: (digitalProduct.fields.productBenefits || []).map((pb) =>
      parseContentfulProductBenefit(pb.fields),
    ),
    acceptedCardSchemaLabel:
      digitalProduct.fields.acceptedCardSchemaLabel || '',
    cardSchemasAccepted: (digitalProduct.fields.acceptedCardsSchemas ||
      []) as string[],

    otherPaymentTypesLabel: digitalProduct.fields.otherPaymentTypesLabel || '',
    otherAcceptedPaymentTypes: (digitalProduct.fields
      .otherAcceptedPaymentTypes || []) as string[],

    howItWorks: digitalProduct.fields.howItWorks || null,
    howItWorksHeadline: digitalProduct.fields.howItWorksHeadline || '',
  }));

const getProductType = (product: IProduct | IBundleProduct): IProductType => {
  const typeMap: Record<string, IProductType> = {
    product: 'sku',
    bundleProduct: 'bundle',
  };

  return typeMap[product.sys.contentType.sys.id];
};

export const getProductSKU = (product: IProduct): string => {
  if (product.fields?.sku_code && product.fields?.sku_code !== '') {
    return product.fields.sku_code;
  }

  return product.fields.sku && product.fields.sku.length > 0
    ? product.fields.sku[0]
    : '';
};

export const getBundleProductSKU = (product: IBundleProduct): string => {
  return product.fields.sku;
};

export const getSKU = (
  product: IBundleProduct | IProduct,
  productType: IProductType,
): string => {
  switch (productType) {
    case 'bundle':
      if (product.sys.contentType.sys.id != 'bundleProduct') {
        return '';
      }

      return getBundleProductSKU(product as IBundleProduct);
    case 'sku':
      if (product.sys.contentType.sys.id !== 'product') {
        return '';
      }

      return getProductSKU(product as IProduct);
  }

  return '';
};

export const parseContentfulProducts = (
  contentfulProducts: (IProduct | IBundleProduct)[],
): IProductDetails[] =>
  contentfulProducts.map((product) => {
    const productType = getProductType(product);
    const skus = getSKU(product, productType);

    return {
      id: product.sys.id,
      sku: skus,
      type: productType,
      name: product.fields.title,
      trackingId: product.fields.trackingId,
      slug: product.fields.trackingId,
      productContent: {
        imageSrc: convertImageToPng(product.fields.images[0].fields.file.url),
        imageAltText: product.fields.images[0].fields.description || '',
      },
      images: product.fields.images.map((image) => ({
        id: image.sys.id,
        imageSrc: convertImageToPng(image.fields.file.url),
        imageAltText: image.fields.description || '',
      })),
      bulletPoints: product.fields.bulletPoints,
      shouldShowHighlight: product.fields.shouldShowHighlight,
      highlightTitle: product.fields.highlightTitle || '',
      highlightDescription: product.fields.highlightDescription || '',
      highlightIcon: product.fields.highlightIcon,
      productBenefits: (product.fields.productBenefits || []).map((pb) =>
        parseContentfulProductBenefit(pb.fields),
      ),

      acceptedCardSchemaLabel: product.fields.acceptedCardSchemaLabel || '',
      cardSchemasAccepted: (product.fields.acceptedCardsSchemas ||
        []) as string[],

      otherPaymentTypesLabel: product.fields.otherPaymentTypesLabel || '',
      otherAcceptedPaymentTypes: (product.fields.otherAcceptedPaymentTypes ||
        []) as string[],
      deliveryTime: product.fields.deliveryTime || null,
      summaryDescription: product.fields.summaryDescription || null,
      moneyBackGuarantee: product.fields.moneyBackGuarantee || '',
      productDescription: product.fields.productDescription || '',
      shippingFee: product.fields.shippingFee || '',
      faqs: (product.fields.faqs || []).map((item) => item.fields),
      numberOfInstallments: product.fields.installments || 1,
      installmentsFeeInfo: product.fields.installmentsFeeInfo || '',
      fullPriceInfo: product.fields.fullPriceInfo || '',
      selectProductLabel: product.fields.selectProductLabel || '',
      selectedProductLabel: product.fields.selectedProductLabel || '',
      shortBenefits: product.fields.shortBenefits || '',
    };
  });

export const getProductByTrackingId = (
  products: (IProduct | IBundleProduct)[],
  trackingId: string,
): IProduct | IBundleProduct =>
  products?.find((product) => product.fields.trackingId === trackingId);

export const reorderCatalogByQuery = (
  products: (IProduct | IBundleProduct)[],
  productsOnQuery: string[],
): (IProduct | IBundleProduct)[] => {
  if (productsOnQuery) {
    return productsOnQuery.reduce<(IProduct | IBundleProduct)[]>(
      (acc, current) => {
        /**
       * Check for available aliases for existing products. This is only
         necessary while the old shop is still supported. The goal is
         to deprecate this logic and remove aliases as soon as the
         storefront becomes the default platform.
       */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const productTrackingId = PRODUCT_ALIASES[current] || current;

        const product = getProductByTrackingId(products, productTrackingId);
        if (product) {
          return [...acc, product];
        }

        return acc;
      },
      [],
    );
  }
  return products;
};

export const getProductCatalog = (
  contentfulProducts: (IProduct | IBundleProduct)[],
  productsOnQuery: string[],
): (IProduct | IBundleProduct)[] => {
  const filteredProducts = reorderCatalogByQuery(
    contentfulProducts,
    productsOnQuery,
  );

  if (filteredProducts.length > 0) {
    return filteredProducts;
  }

  return contentfulProducts;
};

export const joinCatalogProductsForCountry = (
  countryCatalogEntries: EntryCollection<ICatalogFields>,
): IProduct[] => {
  /**
   * We need a list of all products available on a country.
   * uniqueProductsById is an objects of Contentful product information by id,
   * without duplicates.
   */
  const uniqueProductsById = countryCatalogEntries.items.reduce(
    (productsMap, current) => {
      const catalogProductsMap = current.fields.products.reduce(
        (productsById, currentProduct) => ({
          ...productsById,
          [currentProduct.sys.id]: currentProduct,
        }),
        {},
      );

      return {
        ...productsMap,
        ...catalogProductsMap,
      };
    },
    {},
  );

  return Object.values(uniqueProductsById);
};

export const getProductDetailsFromOrder = async (
  order: Order,
  locale: LOCALE_CODE,
): Promise<{
  products: IProductData[];
  largestInstallmentInCart: number;
  hasBusinessAccount: boolean;
}> => {
  const allCountryCatalogEntries = await getCatalogEntriesByCountry(
    getCountryCodeFromISOLocale(locale),
  );

  const productEntriesForCountry = joinCatalogProductsForCountry(
    allCountryCatalogEntries,
  );
  const productsContentful = parseContentfulProducts(productEntriesForCountry);

  const productsInCart = order.line_items
    ?.filter((li) => li.item_type === 'skus' || li.item_type === 'bundles')
    .map((li) => translateProductToStore(li));

  const productCLReference = productsInCart.map((p) => p.reference);

  const productInstallments = productsContentful
    .filter((p) => productCLReference.includes(p.id))
    .map((p) => p.numberOfInstallments);

  const largestInstallmentInCart = Math.max(...productInstallments);

  const products = combineProductsInfo(
    productsContentful,
    productsInCart,
    locale,
  );

  const hasBusinessAccount = !!productsInCart.find(
    (product) => product.skuCode === BUSINESS_ACCOUNT_PRODUCT.sku,
  );

  return { products, largestInstallmentInCart, hasBusinessAccount };
};
