import {
  createClient,
  CreateClientParams,
  ContentfulClientApi,
  EntryCollection,
} from 'contentful';

import { previewInfo } from './preview';

import { forceImgSrcToHttps } from 'shared/services/imageUrl';
import { ShopExperience } from 'shared/constants/ShopExperience';
import {
  IUiCartFields,
  IUiProductSelectionPageFields,
  IUiSignupPageFields,
  ICatalogFields,
  IMarketFields,
  CONTENT_TYPE,
  LOCALE_CODE,
  IUiLoginPageFields,
  IUiCheckoutPageFields,
  IUiProductDetailsPageFields,
  IFormFields,
  IUiThankYouPageFields,
  IUiNotFoundPageFields,
  ICountryFields,
  IUiNotificationsFields,
  INationalIdFormFields,
  IFooterFields,
  IUiCreateOrderPageFields,
  IExperimentalTaxNoticesFields,
} from 'shared/infra/contentful/contentful';
import {
  IParsedNationalIdForm,
  PaymentMethodContent,
} from 'shared/infra/contentful/types';
import { IPaymentMethod } from 'shared/infra/commerceLayer/types';

interface IContentfulQuery {
  locale?: LOCALE_CODE;
  include?: number;
  content_type?: CONTENT_TYPE;
  links_to_entry?: string;
  limit?: number;
  select?: string;
  skip?: number;
  order?: string;
  // for query nested fields (e.g. "fields.name[ne]")
  [key: string]: unknown;
}

export enum ContentfulSearchDepth {
  FIRST_LEVEL = 1,
  SECOND_LEVEL = 2,
  THIRD_LEVEL = 3,
}

interface IContentfulClientApi extends ContentfulClientApi {
  getEntries<T>(query?: IContentfulQuery): Promise<EntryCollection<T>>;
}

const PREVIEW_URL = 'preview.contentful.com';
const DELIVERY_URL = 'cdn.contentful.com';

export const getClient = (): IContentfulClientApi => {
  const isPreview = previewInfo().getPreviewMode();
  const host = isPreview ? PREVIEW_URL : DELIVERY_URL;
  const accessToken = isPreview
    ? process.env.CONTENTFUL_PREVIEW_TOKEN
    : process.env.CONTENTFUL_ACCESS_TOKEN;

  const clientConfig: CreateClientParams = {
    accessToken,
    host,
    space: process.env.CONTENTFUL_SPACE,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
  };

  const client = createClient(clientConfig);

  return client;
};

export const getUICartEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiCartFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'uiCart',
    locale,
  });
};

export const getUIProductSelectionPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiProductSelectionPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiProductSelectionPageFields>({
    content_type: 'uiProductSelectionPage',
    locale,
    include: 2,
  });
};

export const getUIProductDetailsPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiProductDetailsPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiProductDetailsPageFields>({
    content_type: 'uiProductDetailsPage',
    locale,
    include: 2,
  });
};

export const getUISignupPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiSignupPageFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'uiSignupPage',
    locale,
    include: 2,
  });
};

export const getUIThankYouPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiThankYouPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiThankYouPageFields>({
    content_type: 'uiThankYouPage',
    locale,
    include: 2,
  });
};

type FilterCatalogProps = Pick<ICatalogFields, 'channel'> &
  Pick<IMarketFields, 'countryCode'> & {
    locale: LOCALE_CODE;
    searchDepth?: ContentfulSearchDepth;
  };

export const getCatalogEntriesByCountryAndChannel = ({
  countryCode,
  channel = ShopExperience.SIGNUP,
  locale,
  searchDepth = ContentfulSearchDepth.SECOND_LEVEL,
}: FilterCatalogProps): Promise<EntryCollection<ICatalogFields>> => {
  const client = getClient();

  return client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'fields.channel': channel,
    'include': searchDepth,
    locale,
  });
};

export const getCatalogEntriesByCountry = (
  countryCode: string,
  searchDepth = ContentfulSearchDepth.SECOND_LEVEL,
): Promise<EntryCollection<ICatalogFields>> => {
  const client = getClient();

  return client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'include': searchDepth,
  });
};

export const getAvailableChannelsByCountry = async (
  countryCode: string,
  searchDepth = ContentfulSearchDepth.SECOND_LEVEL,
): Promise<string[]> => {
  const client = getClient();

  const catalogEntries = await client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'include': searchDepth,
  });

  const channels = catalogEntries.items?.reduce<string[]>(
    (acc, current) => [...acc, current.fields?.channel],
    [],
  );

  return channels;
};

export const getUILoginPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiLoginPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiLoginPageFields>({
    content_type: 'uiLoginPage',
    locale,
    include: 2,
  });
};

export const getUICreateOrderPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiCreateOrderPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiCreateOrderPageFields>({
    content_type: 'uiCreateOrderPage',
    locale,
    include: 2,
  });
};

export const getMarketEntriesByCountryCode = (
  countryCode: string,
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IMarketFields>> => {
  const client = getClient();

  return client.getEntries<IMarketFields>({
    'content_type': 'market',
    'fields.countryCode': countryCode,
    'locale': locale,
    'include': 3,
  });
};

export const getUICheckoutPageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiCheckoutPageFields>> => {
  const client = getClient();

  return client.getEntries<IUiCheckoutPageFields>({
    content_type: 'uiCheckoutPage',
    locale,
    include: 2,
  });
};

export const getFormEntries = (
  countryCode: string,
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IFormFields>> => {
  const client = getClient();

  return client.getEntries<IFormFields>({
    'content_type': 'form',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    locale,
    'include': 2,
  });
};

export const getUI404PageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiNotFoundPageFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'uiNotFoundPage',
    locale,
  });
};

export const getCountriesWithLocales = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<ICountryFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'country',
    locale,
  });
};

export const getFooterEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IFooterFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'footer',
    locale,
  });
};

export const getPaymentMethodContentByMarketEntries = (
  marketEntries: EntryCollection<IMarketFields>,
): PaymentMethodContent[] => {
  const {
    commerceLayerPaymentMethods,
    paymentMethods: paymentMethodsWithContentfulContent,
  } = marketEntries.items[0]?.fields;
  // like { bankwire - id12345 }
  const paymentMethodsWithCLId = commerceLayerPaymentMethods as Record<
    IPaymentMethod,
    string
  >;

  const output = (paymentMethodsWithContentfulContent || []).map(
    (paymentMethodContent) => {
      const paymentMethodId =
        paymentMethodsWithCLId[
          paymentMethodContent.fields.commerceLayerPaymentType
        ];

      if (!paymentMethodId) {
        // Payment Methods + Commerce Layer Payment Methods
        // throw build time error if contentful is misconfigured
        throw new Error(
          `Payment Method does not exist in Commerce Layer! ${paymentMethodContent.fields.commerceLayerPaymentType}`,
        );
      }

      const iconUrl = paymentMethodContent.fields.icon?.fields?.file?.url;
      return {
        id: paymentMethodId,
        method: paymentMethodContent.fields.commerceLayerPaymentType,
        label: paymentMethodContent.fields.name,
        icon: {
          url: iconUrl ? forceImgSrcToHttps(iconUrl) : null,
          height:
            paymentMethodContent.fields.icon?.fields?.file?.details?.image
              ?.height || 24,
          width:
            paymentMethodContent.fields.icon?.fields?.file?.details?.image
              ?.width || 24,
          name: paymentMethodContent.fields.icon?.fields?.title || '',
        },
        message: paymentMethodContent.fields.paymentMethodMessage || '',
        isAlternativePaymentMethod:
          paymentMethodContent.fields.isAlternativePaymentMethod,
        termsAndConditions:
          paymentMethodContent.fields.termsAndConditions || '',
      } as unknown as PaymentMethodContent;
    },
  );

  return output;
};

export const getUINotificationsEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiNotificationsFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'uiNotifications',
    locale,
  });
};

export const parseNationalIdFormFields = (
  nationalIdFormContent: INationalIdFormFields,
): IParsedNationalIdForm => {
  if (
    !nationalIdFormContent ||
    !nationalIdFormContent.acceptedNationalIdsBusiness ||
    !nationalIdFormContent.acceptedNationalIdsPersonal
  ) {
    return null;
  }

  const acceptedNationalIdsPersonal =
    nationalIdFormContent?.acceptedNationalIdsPersonal
      ? nationalIdFormContent?.acceptedNationalIdsPersonal.map(
          (nationalId) => ({
            ...nationalId.fields,
          }),
        )
      : [];

  const acceptedNationalIdsBusiness =
    nationalIdFormContent?.acceptedNationalIdsBusiness
      ? nationalIdFormContent?.acceptedNationalIdsBusiness.map(
          (nationalId) => ({
            ...nationalId.fields,
          }),
        )
      : [];

  return {
    ...nationalIdFormContent,
    acceptedNationalIdsPersonal: acceptedNationalIdsPersonal.map(
      (acceptedNationalId) => ({
        ...acceptedNationalId,
        inputField: acceptedNationalId.inputField.fields,
      }),
    ),
    acceptedNationalIdsBusiness: acceptedNationalIdsBusiness.map(
      (acceptedNationalId) => ({
        ...acceptedNationalId,
        inputField: acceptedNationalId.inputField.fields,
      }),
    ),

    nationalIdPersonalLabel: nationalIdFormContent?.nationalIdPersonalLabel,
    nationalIdBusinessLabel: nationalIdFormContent?.nationalIdBusinessLabel,
    selectNationalIdLabel: nationalIdFormContent?.selectNationalIdLabel || '',
  };
};

export const getNationalIdContentFieldsFromMarket = (
  marketEntries: EntryCollection<IMarketFields>,
): IParsedNationalIdForm => {
  const marketFields = marketEntries.items[0]?.fields;
  const nationalIdFormContent = marketFields?.nationalIdForm?.fields;

  return parseNationalIdFormFields(nationalIdFormContent);
};

export const getExperimentalTaxNoticesEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IExperimentalTaxNoticesFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'experimentalTaxNotices',
    locale,
  });
};
